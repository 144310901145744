import React from "react";
import Footer from "../../components/footer";

const Header = React.lazy(() => import("../../components/header"));

function RefundPolicy() {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1400px",
          color: "#444",
          fontSize: "0.9em",
          minHeight: "60vh",
          scrollSnapAlign: "start",
          scrollMarginTop: "100px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Cancellation/Refund policy</h2>
        <h3>Here's our cancellation/refund policy:</h3>
        <p>
          Get your 100% money refunded if you don't like the Dopamine Pro
          feature within 30 days of usage without any questions asked period.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default React.memo(RefundPolicy);
