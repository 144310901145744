import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import LOGO from "../../assets/images/footer-logo.svg";
import CALL from "../../assets/images/call.svg";
import WA from "../../assets/images/wa.svg";
import IN from "../../assets/images/in.svg";
import MAIL from "../../assets/images/mail.svg";
import { ReactComponent as VERIFIED } from "../../assets/images/verify-check.svg";
import Drawer from "../../components/drawer";

//Profile images of the team
// ../assets/images/yt.jpeg
// ../assets/images/sg.jpg

import yash from "../../assets/images/yt.jpeg";
import siddhesh from "../../assets/images/sg.jpg";
import SHAVEEN from "../../assets/team/shaveen.jpeg";
import PRATIK from "../../assets/team/pratiksingh.jpeg";
import sarang from "../../assets/team/sarang.jpeg";
import vimal from "../../assets/team/vimal.jpg";
import viswajith from "../../assets/team/viswajith.jpg";
import ameer from "../../assets/team/ameer.jpg";
import abdul from "../../assets/team/abdul.jpg";
import dhwani from "../../assets/team/dhwani.jpeg";
import sanchay from "../../assets/team/sanchay.jpeg";
import vignesh from "../../assets/team/vignesh.jpg";
import pratikagarwal from "../../assets/team/pratikagarwal.jpeg";
import renuka from "../../assets/team/renuka.jpeg";
import mitmehta from "../../assets/team/mitmehta.jpeg";
import arunbalaji from "../../assets/team/arunbalaji.jpeg";
import prince from "../../assets/team/prince.jpg";
import sarvesh from "../../assets/team/sarvesh.jpg";
import tinku from "../../assets/team/tinku.jpeg";
import aarti from "../../assets/team/aarti.jpeg";
import kuldeep from "../../assets/team/kuldeep.jpg";
import arunpachauri from "../../assets/team/arunpachauri.jpeg";
import ronald from "../../assets/team/ronald.jpeg";

// const ismobile = window.innerWidth <= 768;

export default function Team() {
  // const navigate = useNavigate();
  const params = useParams();
  const { name } = params;
  const [profile, setProfile] = useState({});

  const names = [
    {
      name: "yashthakur",

      designation: "Co-Founder/CTO",
      title: "Yash Thakur",
      url: yash,
      call: "tel:+919892228599",
      whatsapp: "https://wa.me/+919892228599?text=Hello",
      linkedin: "https://www.linkedin.com/in/thakuryash",
      mail: "mailto:yt@dopamine.fit",
    },
    {
      name: "siddheshghuge",

      designation: "Founder",
      title: "Siddhesh",
      url: siddhesh,
      call: "tel:+919867870802",
      whatsapp: "https://wa.me/+919867870802?text=Hello",
      linkedin: "https://www.linkedin.com/in/siddhesh-t-ghuge-175063116/",
      mail: "mailto:siddhesh.g@dopamine.fit",
    },
    {
      name: "shaveenraj",

      designation: "Product Manager",
      title: "Shaveen Raj",
      url: SHAVEEN,
      call: "tel:+919840325711",
      whatsapp: "https://wa.me/+919840325711?text=Hello",
      linkedin: "http://linkedin.com/in/shaveenraj",
      mail: "mailto:shaveenraj@thecubeclub.com",
    },
    {
      name: "sarangvs",

      designation: "Flutter Developer ",
      title: "Sarang vs",
      url: sarang,
      call: "tel:+918848561898",
      whatsapp: "https://wa.me/+918848561898?text=Hello",
      linkedin: "https://www.linkedin.com/in/sarang-vs-24a837213",
      mail: "mailto:sarang@dopamine.fit",
    },
    {
      name: "vimalkesharavani",

      designation: "Operations Manager",
      title: "Vimal Kesharavani",
      url: vimal,
      call: "tel:+919653016072",
      whatsapp: "https://wa.me/+919653016072?text=Hello",
      linkedin: "https://www.linkedin.com/in/vimal-kesharavani-55b804174",
      mail: "mailto:vimal.kesharavani@thecubeclub.com",
    },
    {
      name: "viswajithka",

      designation: "Flutter Developer",
      title: "Viswajith K A",
      url: viswajith,
      call: "tel:+919447617999",
      whatsapp: "https://wa.me/+919447617999?text=Hello",
      linkedin: "https://www.linkedin.com/in/viswajith-k-a-441561137/",
      mail: "mailto:viswajith@dopamine.fit",
    },
    {
      name: "pratiksingh",

      designation: "Frontend Developer",
      title: "Pratik Singh",
      url: PRATIK,
      call: "tel:+918454078687",
      whatsapp: "https://wa.me/+918454078687?text=Hello",
      linkedin: "https://www.linkedin.com/in/pratik-singh-65ba361b1/",
      mail: "mailto:pratik.singh@dopamine.fit",
    },
    {
      name: "ameercm",

      designation: "Full Stack Dev",
      title: "Muhammed Ameer C M",
      url: ameer,
      call: "tel:+919567713861",
      whatsapp: "https://wa.me/+919567713861?text=Hello",
      linkedin: "https://www.linkedin.com/in/ameer-c-m/",
      mail: "mailto:ameer@dopamine.fit",
    },
    {
      name: "abdulrazak",

      designation: "Operations Manager",
      title: "Abdul Razak",
      url: abdul,
      call: "tel:+918652657979",
      whatsapp: "https://wa.me/+918652657979?text=Hello",
      linkedin: "https://www.linkedin.com/in/abdul-razak-87581a171",
      mail: "mailto:abdul.razak@thecubeclub.com",
    },
    {
      name: "dhwanidoshi",

      designation: "AI Engineer",
      title: "Dhwani Doshi",
      url: dhwani,
      call: "tel:+919029224624",
      whatsapp: "https://wa.me/+919029224624?text=Hello",
      linkedin: "https://www.linkedin.com/in/dhwani-doshi-07/",
      mail: "mailto:doshidhwani07@gmail.com",
    },
    {
      name: "sanchaybaijal",

      designation: "Marketing Manager",
      title: "Sanchay Baijal",
      url: sanchay,
      call: "tel:+919820928641",
      whatsapp: "https://wa.me/+919820928641?text=Hello",
      linkedin: "https://www.linkedin.com/in/sanchaybaijal/",
      mail: "mailto:sanchay.baijal@thecubeclub.com",
    },
    {
      name: "vignesh",

      designation: "Jr. Designer ",
      title: "Vignesh",
      url: vignesh,
      call: "tel:+916382868895",
      whatsapp: "https://wa.me/+916382868895?text=Hello",
      linkedin: "http://www.linkedin.com/in/iamvig-nesh",

      mail: "mailto:vignesh@dopamine.fit",
    },
    {
      name: "pratikagarwal",

      designation: "Co-Founder",
      title: "Pratik Agarwal",
      url: pratikagarwal,
      call: "tel:+919867071117",
      whatsapp: "https://wa.me/+919867071117?text=Hello",
      linkedin: "https://www.linkedin.com/in/pratik-agarwal-687b968/",
      mail: "mailto:pratik@thecubeclub.com",
    },
    {
      name: "renukagangthade",

      designation: "Customer Relationship Manager",
      title: "Renuka Gangthade",
      url: renuka,
      call: "tel:+918879034579",
      whatsapp: "https://wa.me/+918879034579?text=Hello",
      linkedin: "http://linkedin.com/in/renuka-gangthade-49b424253",

      mail: "mailto:renuka.g@thecubeclub.com",
    },
    {
      name: "mitmehta",

      designation: "Head of growth",
      title: "Mit Mehta",
      url: mitmehta,
      call: "tel:+919769173713",
      whatsapp: "https://wa.me/+919769173713?text=Hello",
      linkedin: "https://www.linkedin.com/in/mit-mehta-generalist/",
      mail: "mailto:mit@thecubeclub.com",
    },
    {
      name: "arunbalaji",

      designation: "UI UX Designer",
      title: "Arun balaji",
      url: arunbalaji,
      call: "tel:+916380495403",
      whatsapp: "https://wa.me/+916380495403?text=Hello",
      linkedin: "https://www.linkedin.com/in/arun-balaji-88597921a",
      mail: "mailto:arun@dopamine.fit",
    },
    {
      name: "roshanverma",

      designation: "Creative Director",
      title: "Roshan Verma",
      url: prince,
      call: "tel:+919833133094",
      whatsapp: "https://wa.me/+919833133094?text=Hello",

      linkedin: "",
      mail: "mailto:roshan.v@thecubeclub.com",
    },
    {
      name: "sarveshmore",

      designation: "Business Analyst",
      title: "Sarvesh Prasad More",
      url: sarvesh,
      call: "tel:+919167601042",
      whatsapp: "https://wa.me/+919167601042?text=Hello",
      linkedin: "https://www.linkedin.com/in/sarveshmore/",
      mail: "mailto:sarvesh.m@thecubeclub.com",
    },
    {
      name: "tinkuagarwal",

      designation: "Manager - Accounts",
      title: "Tinku Agarwal",
      url: tinku,
      call: "tel:+919609450000",
      whatsapp: "https://wa.me/+919734220504?text=Hello",
      linkedin: "https://www.linkedin.com/in/tinku-agarwal-6a3bb2112",
      mail: "mailto:tinku@thecubeclub.com",
    },
    {
      name: "aartiyadav",
      designation: "HR Manager",
      title: "Aarti Yadav",
      url: aarti,
      call: "tel:+919769308177",
      whatsapp: "https://wa.me/+919769308177?text=Hello",
      linkedin: "http://linkedin.com/in/aarti-yadav-63381615a",

      mail: "mailto:aarti@dopamine.fit",
    },
    {
      name: "kuldeeprajpurohit",

      designation: "Ecommerce marketing intern ",
      title: "Kuldeep Rajpurohit ",
      url: kuldeep,
      call: "tel:+919328416228",
      whatsapp: "https://wa.me/+919328416228?text=Hello",
      linkedin:
        "https://www.linkedin.com/in/kuldeep-singh-rajpurohit-759610255",
      mail: "mailto:kuldeep.r@thecubeclub.com",
    },
    {
      name: "arunpachauri",
      designation: "Senior Community Manager",
      title: "Arun Pachauri",
      url: arunpachauri,
      call: "tel:+916395226520",
      whatsapp: "https://wa.me/+916395226520?text=Hello",

      linkedin: "https://www.linkedin.com/in/arun-kant-pachauri-62a76882/",
      mail: "mailto:arun.p@dopamine.fit",
    },
    {
      name: "ronaldpinto",
      designation: "Digital Marketing Manager",
      title: "Ronald Pinto",
      url: ronald,
      call: "tel:+919819560419",
      whatsapp: "https://wa.me/+919819560419?text=Hello",
      linkedin: "",
      mail: "mailto:ronald@thecubeclub.com",
    },
    {
      name: "devirani",
      designation: "Assitant Manager-Finance",
      title: "Devi Rani",
      url: "",
      call: "tel:+917498233842",
      whatsapp: "https://wa.me/+919769025536?text=Hello",
      linkedin: "",
      mail: "mailto:pranaam.ac@gmail.com",
    },
    {
      name: "rinaldomarian",
      designation: "Supply chain & Logistics Intern",
      title: "Rinaldo Marian",
      url: "",
      call: "tel:+918291238324",
      whatsapp: "https://wa.me/+918291238324?text=Hello",
      linkedin: "",
      mail: "mailto:supply.intern@thecubeclub.com",
    },
    {
      name: "rudreshmayekar",
      designation: "UI/UX intern",
      title: "Rudresh Mayekar",
      url: "",
      call: "tel:+918828189469",
      whatsapp: "https://wa.me/+918828189469?text=Hello",
      linkedin: "",
      mail: "mailto:rudresh.mayekar@dopamine.fit",
    },
  ];

  const socialIcons = [
    {
      name: "call",
      icon: CALL,
      link:
        // name === "yashthakur"
        //   ? "tel:+919892228599"
        //   : name === "siddheshghuge"
        //   ? "tel:+919867870802"
        //   : "",
        profile?.call,
    },
    {
      name: "whatsapp",
      icon: WA,
      link:
        // name === "yashthakur"
        //   ? "https://wa.me/+919892228599?text=Hello"
        //   : name === "siddheshghuge"
        //   ? "https://wa.me/+919867870802?text=Hello"
        //   : "",
        profile?.whatsapp,
    },
    //     {
    //       name: "instagram",
    //       icon: INSTA,
    //       link:
    //         // name === "yashthakur"
    //         //   ? "https://instagram.com/its.yash.thakur"
    //         //   : name === "siddheshghuge"
    //         //   ? "https://instagram.com/ghugesiddhesh?igshid=YmMyMTA2M2Y="
    //         //   : "",
    //         profile.instagram,
    //     },
    {
      name: "linkedin",
      icon: IN,
      link:
        // name === "yashthakur"
        //   ? "https://www.linkedin.com/in/thakuryash"
        //   : name === "siddheshghuge"
        //   ? "https://www.linkedin.com/in/siddhesh-t-ghuge-175063116/"
        //   : "",
        profile?.linkedin,
    },
    {
      name: "mail",
      icon: MAIL,
      link:
        // name === "yashthakur"
        //   ? "mailto:yt@dopamine.fit"
        //   : name === "siddheshghuge"
        //   ? "mailto:siddhesh.g@dopamine.fit"
        //   : "",
        profile?.mail,
    },
  ];

  useEffect(() => {
    return setProfile(names.find((data) => data.name === name));
  }, [name]);

  return (
    <Drawer open showImage disableEscapeKeyDown transitionDuration={1}>
      <div
        style={{ backgroundImage: `url(${profile?.url}) ` }}
        className="team-drawer-div"

        // className={
        //   name === "yashthakur"
        //     ? "team-drawer-div yash-bg"
        //     : name === "siddheshghuge"
        //     ? "team-drawer-div siddhesh-bg"
        //     : "team-drawer-div"
        // }
      >
        <div className="team-bg-overlay"></div>
        <div className="content-div">
          <div className="title">
            <div className="name">
              <h2>{profile?.title}</h2>
              <VERIFIED
                style={{
                  height: "1.3rem",
                  width: "1.3rem",
                  marginLeft: "0.4rem",
                }}
              />
            </div>
            <p className="tag">{profile?.designation}</p>
          </div>
          <div className="social-div">
            <p className="connect-para">Connect with me</p>
            <div className="icon-div">
              {socialIcons.map((icon, i) => {
                return (
                  <Button
                    key={icon?.name}
                    href={icon?.link}
                    target="_blank"
                    className="social-btn"
                  >
                    <img src={icon?.icon} alt={icon?.name} />
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="drawer-footer">
            <a href="https://onelink.to/5z9d3p">
              <img src={LOGO} alt="dopamine" width={94} />
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
