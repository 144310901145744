import React from "react";
import Footer from "../../components/footer";

const Header = React.lazy(() => import("../../components/header"));

function ContactUs() {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1400px",
          color: "#444",
          fontSize: "0.9em",
          minHeight: "60vh"
        }}
      >
        <h2 style={{ textAlign: "center" }}>Contact us</h2>
        <p>
                We would love to hear from you and connect with you! 
        </p>
        <p>
                <b>Mail us on: </b><a href="mailto:contact@dopamine.fit" target="_blank" rel="noreferrer">contact@dopamine.fit</a>
        </p>
        <p>
                <b>Call us on: </b><a href="tel:+91-9137534807" target="_blank" rel="noreferrer">+91-9137534807</a>
        </p>
        <h3>Meet us at:</h3>
        <p>
                Dopamine HQ,<br/>
                2nd floor, V Umesh, Building 5, <br/>
                MCHS Colony, Stage 2, BTM Layout, <br/>
                Bengaluru, <br/>
                Karnataka 560076<br/>
                <a href="https://goo.gl/maps/R1C2WhoM4w7c2ekg7" target="_blank" rel="noreferrer">
                        (Google Maps)
                </a>
        </p>
      </div>
      <Footer />
    </>
  );
}


export default React.memo(ContactUs)