import { containerClasses } from '@mui/material'
import React from 'react'

const ConfirmationStep = ({type, daysRemaining, handleRestore}) => {
    const confirmationTypes = {
        newRequest: "Your deletion request has been sent",
        oldRequest: "You have already requested for deletion",
        deleted: "Your account is being deleted",
        restored: "Account restored"
       }
    
  return (
    <div className='account-deletion-container'>
    <div style={{flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
          <p style={{ fontSize: 28, fontWeight: 500,lineHeight:"32px",  margin: "46px auto 0 auto", textAlign: 'center'}}>
            {confirmationTypes[type]}
          </p>
          <p style={{width: '75%', color: "#6F7379", fontSize: 16 , margin: '10px auto 44px auto', textAlign: 'center'}}>
         {type === 'deleted' ? `All data & features associated with your account
(Digital profile, business tools, customer information etc.) will be parmanently deleted in
${daysRemaining} days. The information can’t be recovered once the account deleted.` : type === 'restored' ? "We are glad to have you back with us!" : "Once request is approved, you will have 30 days to cancel your deletion request, if you don’t want to lose any account data."}
          </p>
          </div>
          {type === 'deleted' && <button className='action-button-bottom' onClick={handleRestore}>Restore Account</button>}
        </div>
  )
}

export default React.memo(ConfirmationStep)
