import React, { useRef, useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { MainContext } from "../../utils/context";

const Header = React.lazy(() => import("../../components/header"));
const Footer = React.lazy(() => import("../../components/footer"));

function Aboutus() {
  const [activeIndex, setActiveIndex] = useState(0);
  const paragraphRefs = useRef([]);
  const observerRef = useRef(null);
  const ismobile = window.innerWidth <= 768;
  const { setIsRedirect } = useContext(MainContext);

  const location = useLocation();

  useEffect(() => {
    const handleObserver = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = paragraphRefs.current.indexOf(entry.target);
          setActiveIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(handleObserver, {
      threshold: ismobile ? 0.9 : 1,
      rootMargin: ismobile ? "0px 0px -30% 0px" : "0px",
    });
    observerRef.current = observer;

    // Handle the initial render case
    setActiveIndex(0);

    paragraphRefs.current.forEach((paragraph) => {
      observer.observe(paragraph);
    });

    return () => {
      observer.disconnect();
    };
  }, [ismobile]);

  const addParagraphRef = (el) => {
    if (el) {
      paragraphRefs.current.push(el);
    }
  };

  return (
    <>
      <Header isHide={activeIndex === 6} />

      {ismobile ? (
        <div className="about-page ">
          <div className="about-right-parent">
            <div className="about-left">
              <p className="heading-small ">
                {activeIndex < 2 ? "We believe in" : "That's where"}
              </p>
              {activeIndex < 2 ? (
                <p className="heading-large ">
                  The power of
                  <span style={{ color: "rgba(255, 74, 1, 1)" }}>
                    {" "}
                    real
                    <br />
                    connection
                  </span>
                </p>
              ) : (
                <p className="heading-large ">
                  <span style={{ color: "rgba(255, 74, 1, 1)" }}>
                    {" "}
                    Dopamine{" "}
                  </span>{" "}
                  comes in.
                </p>
              )}
            </div>
            <div className="about-right" style={{ marginTop: 50 }}>
              <div
              // style={{
              //   display: "flex",
              //   justifyContent: "flex-end",
              //   flexDirection: "column",
              // }}
              >
                <p
                  className="about-detail-1"
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 0 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                >
                  In a world where social 'media' has often replaced genuine
                  interaction with curated content and superficial connections,
                  we're here to change the game.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 1 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  Remember when social media was about connecting with people,
                  sharing authentic moments, and feeling supported and loved? We
                  do too. But somewhere along the way, it lost its authenticity.
                  Friends became strangers, moments became staged, and sharing
                  became a performance.
                </p>

                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 2 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  We're not just another social media platform; we're a movement
                  to bring back real connection. Our mission is simple: to
                  emotionally and socially connect individuals through the
                  sharing of healthy lifestyle moments, instantly.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 3 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  With Dopamine, you're not just another user in a sea of faces.
                  You're part of a community of like- minded individuals who
                  value authenticity and real do too. But somewhere along the
                  way, it lost its connection above all else.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 4 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  We believe in making wellness fun and accessible to everyone.
                  Our platform is designed to foster genuine connections, where
                  you can share your ups and downs, your victories and
                  struggles, without the pressure to portray perfection.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 5 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  So, if you're tired of the social media popularity contest,
                  the pressure to look perfect, and the constant need for
                  validation, join us at Dopamine. Let's make genuine
                  connections, share real moments, and make wellness fun
                  together.
                </p>

                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 6 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  Release your Dopamine.
                  <br />
                  <br />
                  Siddhesh Ghuge
                  <br />
                  Founder & CEO
                  <br />
                  Dopamine
                  <br />
                  <br />
                </p>
                {activeIndex === 6 &&
                  ismobile &&
                  location.pathname.toLocaleLowerCase() === "/about" && (
                    // <Link to={"/"}>
                    //   <button
                    //     className="continue-cta filled slide-in-right"
                    // style={{
                    //   width: "auto",
                    //   padding: "0px 15px",
                    //   fontSize: 15,
                    //   margin: 0,
                    // }}
                    //     onClick={() => setIsRedirect(true)}
                    //   >
                    //     Get App
                    //   </button>
                    // </Link>
                    <button className="slide-in-right downloadApp-btn">
                      <a
                        href="https://onelink.to/pcgew6"
                        style={{
                          textDecoration: "none",
                          color: "rgba(255, 255, 255, 1)",
                          width: "auto",
                          padding: "0px 15px",
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Get App
                      </a>
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="about-page">
          <div className="about-left slide-in-right">
            <p className="heading-small">
              {activeIndex < 2 ? "We believe in" : "That's where"}
            </p>
            {activeIndex < 2 ? (
              <p className="heading-large">
                The power of
                <span style={{ color: "rgba(255, 74, 1, 1)" }}>
                  {" "}
                  real
                  <br />
                  connection
                </span>
              </p>
            ) : (
              <p className="heading-large">
                <span style={{ color: "rgba(255, 74, 1, 1)" }}> Dopamine </span>{" "}
                comes in.
              </p>
            )}
          </div>
          <div className="about-right-parent">
            <p className="about-title">About Us</p>
            <div className="about-right">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <p
                  className="about-detail-1"
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 0 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                >
                  In a world where social 'media' has often replaced
                  <br /> genuine interaction with curated content and
                  <br /> superficial connections, we're here to change the
                  <br /> game.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 1 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  Remember when social media was about
                  <br /> connecting with people, sharing authentic
                  <br /> moments, and feeling supported and loved? We
                  <br /> do too. But somewhere along the way, it lost its
                  <br />
                  authenticity. Friends became strangers, moments
                  <br /> became staged, and sharing became a<br /> performance.
                </p>

                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 2 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  We're not just another social media platform; we're
                  <br /> a movement to bring back real connection. Our
                  <br /> mission is simple: to emotionally and socially
                  <br /> do too. But somewhere along the way, it lost its
                  <br />
                  connect individuals through the sharing of healthy
                  <br /> lifestyle moments, instantly.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 3 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  With Dopamine, you're not just another user in a
                  <br /> sea of faces. You're part of a community of like-
                  <br /> minded individuals who value authenticity and real
                  <br /> do too. But somewhere along the way, it lost its
                  <br />
                  connection above all else.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 4 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  We believe in making wellness fun and accessible
                  <br /> to everyone. Our platform is designed to foster
                  <br /> genuine connections, where you can share your
                  <br /> ups and downs, your victories and struggles,
                  <br />
                  without the pressure to portray perfection.
                </p>
                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 5 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  So, if you're tired of the social media popularity
                  <br /> contest, the pressure to look perfect, and the
                  <br /> constant need for validation, join us at Dopamine.
                  <br /> Let's make genuine connections, share real
                  <br />
                  moments, and make wellness fun together.
                  <br />
                  <br />
                  Because ultimately, it's the connections we make
                  <br />
                  with others that bring us true happiness.
                </p>

                <p
                  ref={addParagraphRef}
                  style={{
                    color:
                      activeIndex === 6 ? "black" : "rgba(51, 51, 51, 0.04)",
                  }}
                  className="about-detail-2"
                >
                  Release your Dopamine.
                  <br />
                  <br />
                  Siddhesh Ghuge
                  <br />
                  Founder & CEO
                  <br />
                  Dopamine
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
}

export default React.memo(Aboutus);
