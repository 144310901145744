import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { MainContextProvider } from "./utils/context";
import Team from "./pages/team";
import Aboutus from "./pages/Other/Aboutus";
import Pricing from "./pages/Other/Pricing";
import RefundPolicy from "./pages/Other/RefundPolicy";
import Contactus from "./pages/Other/Contactus";
import AccountDeletion from "./pages/Other/Deletion/AccountDeletion";
import LandingMain from "./pages/HomeNew/LandingMain";

const HomePageNew = lazy(() => import("./pages/HomeNew"));
const OnboardingDrawer = lazy(() => import("./pages/Onboarding"));
const BusinessProfile = lazy(() => import("./pages/BusinessProfile"));
const Service = lazy(() => import("./pages/Service"));
const TermsAndConditions = lazy(() =>
  import("./pages/Other/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("./pages/Other/PrivacyPolicy"));
const ExpertPage = lazy(() => import("./pages/Expert"));
const BrandPage = lazy(() => import("./pages/Brand"));
const CentrePage = lazy(() => import("./pages/Centre"));
// const LiveSession = lazy(() => import("./pages/Other/LiveSession"));
const InstagramLoader = lazy(() => import("./components/InstagramLoader"));

const theme = createTheme({
  palette: { primary: { main: "#ff4a01" }, text: { primary: "#222831" } },
  typography: { fontFamily: '"Poppins", sans-serif, -apple-system' },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { textTransform: "none" },
        containedPrimary: {
          borderRadius: 10,
          background: "linear-gradient(271.75deg, #FF4A01 0%, #FF7F4C 100%)",
          ":disabled": { background: "#FDD0BF", color: "#fff" },
        },
        containedSecondary: {
          borderRadius: 10,
          background: "#222831",
          ":disabled": { background: "#a6a8ac", color: "#fff" },
          ":hover": { background: "#181B1F" },
        },
      },
    },
    MuiOutlinedInput: { styleOverrides: { root: { backgroundColor: "#fff" } } },
  },
});

// const TRACKING_ID = "G-7M7ZZD0BQV"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

// Near entry of your product, init Mixpanel

function App() {
  return (
    <Suspense fallback={<div className="center-loading-text">Loading...</div>}>
      <MainContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Suspense
              fallback={
                <Backdrop open>
                  <CircularProgress />
                </Backdrop>
              }
            >
              <Routes>
                {/* <Route path="/" element={<HomePage />} /> */}
                <Route path="/" element={<HomePageNew />} />
                <Route path="/instagram" element={<InstagramLoader />} />
                {/* <Route path="/home-new" element={<HomePageNew />} /> */}
                {/* <Route path="/home" element={<HomePageTwo />} /> */}
                <Route path="/expert" element={<ExpertPage />} />
                <Route path="/brand" element={<BrandPage />} />
                <Route path="/centre" element={<CentrePage />} />
                <Route path="/:utm_source" element={<HomePageNew />} />
                <Route path="/onboarding" element={<OnboardingDrawer />} />
                <Route path="/u/:username" element={<BusinessProfile />} />
                <Route path="/team/:name" element={<Team />} />
                <Route
                  path="/u/:username/chat"
                  element={<BusinessProfile chatView />}
                />
                <Route path="/u/:username/service" element={<Service />} />
                <Route
                  path="/u/:username/service/:serviceId"
                  element={<Service detailView />}
                />
                {/* <Route
                  path="/live-session/:serviceId"
                  element={<LiveSession />}
                /> */}
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<Aboutus />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/contact-us" element={<Contactus />} />
                <Route
                  path="/privacy/account-deletion"
                  element={<AccountDeletion />}
                />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </MainContextProvider>
    </Suspense>
  );
}

export default App;
