import React from "react";
import LEFTARROW from "../../assets/images/LEFTARROW.svg";

const Heading = ({ title, step, setStep }) => {
  return (
    <div className="heading-AD">
      {step !== 0 && (
        <img
          onClick={() => setStep((prev) => (prev ? prev - 1 : prev))}
          src={LEFTARROW}
          style={{ cursor: "pointer" }}
        />
      )}
      {step === 0 && (
        <p style={{ fontSize: 24, fontWeight: 600, margin: "auto" }}>
          Delete account
        </p>
      )}
    </div>
  );
};

export default React.memo(Heading);
