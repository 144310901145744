import React from "react";
import { Drawer as Sidebar } from "@mui/material";
import { Image } from "mui-image";
import TITLE from "../assets/images/cardTitle.svg";
import LIGHT from "../assets/images/Light.svg";
import CARD from "../assets/videos/Card.mp4";
import FALLBACK from "../assets/images/hero-section-bg-fallback.webp";

const ismobile = window.innerWidth <= 768;

function Drawer({
  open = false,
  showImage = false,
  setShowImage = () => {},
  closeHandler = () => {},
  disableEscapeKeyDown = false,
  transitionDuration = 500,
  children,
  oldBgColor = false,
}) {
  return (
    <Sidebar
      className="sidebar"
      open={open}
      anchor={ismobile ? "bottom" : "right"}
      PaperProps={{
        sx: ismobile
          ? { backgroundColor: "#EEEEEE", top: 0 }
          : {
              width: "55vh",
              overflowX: "hidden",
              // backgroundColor: "#090200",
              boxShadow: "-7.43419px 0px 74px rgba(255, 74, 1, 0.3)",
              borderTop: "1px solid",
              paddingLeft: "1px",
              // paddingTop: "1px",
              borderImage:
                !oldBgColor &&
                "linear-gradient(127.27deg, #FFFFFF 2.77%, rgba(255, 255, 255, 0) 34.92%)",

              background: oldBgColor
                ? "#EEEEEE"
                : "linear-gradient(127.27deg, #FFFFFF 2.77%, rgba(255, 255, 255, 0) 34.92%)",

              // boxShadow: "-7.43419px 0px 74px rgba(255, 74, 1, 0.3)",

              // border-image-source: ;

              borderRadius: "25px 0px 0px 25px",
            },
      }}
      BackdropProps={{
        className: "drawer-backdrop-blur",
        sx: {
          background: "#090200",
          borderLeft:
            "1px solid linear-gradient(127.27deg, #FFFFFF 2.77%, rgba(255, 255, 255, 0) 34.92%)",
        },
      }}
      onClose={(event) => {
        if (
          event.target.classList.contains("sidebar") ||
          event.target.closest(".sidebar") !== null
        ) {
          // Clicked within the sidebar, do not close
          return;
        }
        setShowImage(false);
        closeHandler();
      }}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {showImage && (
        <div
          className="drawer-backdrop-text"
          style={{
            backgroundColor: "#000",
            textAlign: "center",
            paddingTop: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            duration={400}
            src={TITLE}
            width="100%"
            style={{ width: "50%", marginLeft: "36px" }}
          />

          <video
            className="hero-video-card-drawer"
            autoPlay
            loop
            muted
            style={{
              // width: "30%",
              maxWidth: ismobile ? "30%" : "30%",
              minWidth: ismobile ? "30%" : "30%",
              marginTop: "85px",

              //     animationName: "slidein",
              //     animationDuration: "3s",
              //     animationDelay: "2s",
            }}
            playsInline
            //   poster={AI_CARD}
          >
            <source src={CARD} type="video/mp4" />
            <img
              src={FALLBACK}
              title="Your browser does not support the <video> tag"
              alt="video fallback"
            />
          </video>
          <Image
            duration={400}
            src={LIGHT}
            width="100%"
            style={{ width: "75%" }}
          />
        </div>
      )}
      {children}
    </Sidebar>
  );
}

export default React.memo(Drawer);
