import React from "react";
import Footer from "../../components/footer";

const Header = React.lazy(() => import("../../components/header"));

function Pricing() {
  return (
    <>
      <Header />
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1400px",
          color: "#444",
          fontSize: "0.9em",
          minHeight: "60vh",
          scrollSnapAlign: "start",
          scrollMarginTop: "50px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Pricing</h2>
        <p>
          Dopamine is a free platform for businesses to start their journey and
          scale operations.
        </p>
        <p>
          Dopamine has a PRO plan for businesses to get additional features for
          scaling their operations and getting premium access to community,
          tools and banking.
        </p>
        <p>
          Dopamine Pro is offered for 9999/- 2999/- for 12months for experts.
        </p>
        <p>
          Expert can create their services and sell to their customers via
          dopamine basis on their service pricings.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default React.memo(Pricing);
