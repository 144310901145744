import React, { useState, useEffect } from "react";
import Footer from "../../../components/footer";
import Heading from "../../../components/AccountDeletion/Heading";
import TextField from "@mui/material/TextField";
import Divider from '@mui/material/Divider';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { ReactComponent as IWA } from "../../../assets/images/input-whatsapp.svg";
import { apiCall } from "../../../utils/helpers";
import ConfirmationStep from "../../../components/AccountDeletion/ConfirmationStep";


const Header = React.lazy(() => import("../../../components/header"));

const AccountDeletion = () => {
  const [title, setTitle] = useState("Delete account");
  const [button, setButton] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedValue, setSelectedValue] = useState("I don’t need it anymore");
  const [description, setDescription] = useState('')
  const [confirmationValue, setConfirmationValue] = useState('')
  const [showError, setShowError] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [loginType, setLoginType] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isAccDeleted, setIsAccDeleted] = useState(null)
  const [isRequested, setIsRequested] = useState(false)
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [daysRemaining, setDaysRemaining] = useState(null)
  const [confirmationType, setConfirmationType] = useState(null)
  const [userId, setUserId] = useState(null)
  
  const defaultErrorState = {
    name: null,
    mobile: null,
    email: null,
    otp: null,
    delete: null,
    notFound: null
  }

  const [errors, setErrors] = useState(defaultErrorState)

  useEffect(() => {
    console.log("step : ", step)
    if(step === 0){
      setConfirmationType(null)
      return
    }
    if(!userInfo && !confirmationType && step > 2){
      setStep(1)
      return
    } 
    if(confirmationType === 'deleted' && step > 1 && step < 5){
      setStep(0)
      return
    }
    if(!confirmationType && step === 5){
      setStep(1)
      return
    }
    if(confirmationType && confirmationType !== 'deleted' && step > 2 && step < 5){
      setStep(1)
      return
    }
  },[step])
 
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClick = () => {
    /* if (stepNo === 0) {
      setStep(1);
      setButton(true);
    } else {
      setStep(step + 1);
      console.log("Step not 0");
    } */
    if(step < screens.length - 1){
      setStep((currentStep) => (currentStep + 1))
    }
    
  };
  const resetErrors = (field) => {
    setTimeout(() => {
      setErrors(currentState => {return {...currentState, [field]: null}})
    },3000)
  }
  const handleSendOTP = (type) => {
    // send otp and wait for response
    setIsAccDeleted(false)
    setIsRequested(false)
    setDaysRemaining(null)
    setLoginType(type)
    
    if(name === ""){
      console.log("Name is empty")
      setErrors((currentState) => { return {...currentState, name: "Please enter your name"}})
      resetErrors("name")
      return
    }
    if(type === 'mobile' && !mobile.match(/[1-9]{1}[0-9]{9}/)){
      console.log("Mobile should contain only numbers")
      setErrors(currentState => { return {...currentState, mobile: "Phone number should contain 10 digits"}})
      resetErrors("mobile")
      return
    }
    
    if(type === 'email' && email.length === 0){
      console.log("Email is empty")
      setErrors(currentState => { return {...currentState, email: "Please enter your email"}})
      resetErrors("email")
      return
    }
    if(type === 'email' && !email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )){
      console.log("Format of email is incorrect")
      setErrors(currentState => {return {...currentState, email: "Please enter a valid email"}})
      resetErrors("email")
      return
    }

    setIsLoading(true)
    const payload = type === 'mobile' ? {
      mobile: mobile,
      login_type: type,
      name: name,
    } : {
      email: email,
      login_type: type,
      name: name
    }

    console.log(payload)
    apiCall.post("v1/user/sendOtp", payload).then((res) => {
      setIsLoading(false)
      console.log(res.data)
      const {data, msg} = res.data
      console.log(res.status)
      if(res.status == 404){
      setErrors(currentState => {return {...currentState, notFound: "User not found. Please login using a registered phone number or email."}})
      resetErrors("notFound")
      return
      }
      if(data.isDeleted == 1){
        console.log(msg)
        setIsAccDeleted(true)
        setConfirmationType("deleted")
        setDaysRemaining(data.daysRemaining)
        setUserId(data.user_id)
        setStep(5)
      }else{
        handleClick()
      }

    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    })
    
  }

  const handleVerifyOTP = () => {

    if(otp.length === 0){
      console.log("OTP is empty")
      setErrors(currentState => {return {...currentState, otp: "Please enter your otp"}})
      resetErrors("otp")
      return
    }
    if(!otp.match(/^\d+$/)){
      console.log("Invalid otp")
      setErrors(currentState => {return {...currentState, otp: "Please enter a valid otp"}})
      resetErrors("otp")
      return
    }
    setIsLoading(true)
    const payload = loginType === 'mobile' ? {
      otp: otp,
      mobile: mobile,
      api_name: "verifyOtp"
    } : {
      otp: otp,
      email: email,
      api_name: "verify_otp"
    }
     apiCall.post("v1/user/verifyOtp", payload).then((res) => {
      setIsLoading(false)
      console.log(res.data)
      const {success, msg, data} = res.data
      if(success){
        setUserInfo(data)
        if(data.status === 'requested'){
          setIsRequested(true)
          setConfirmationType('oldRequest')
          setStep(5)
        }else{
          handleClick()
        }
      }else{
        setErrors(currentState => {return {...currentState, otp: msg}})
      resetErrors("otp")
      }
    }).catch((error) => {
      setIsLoading(false)
      console.log(error)
    })
 
   
    
  }
  const handleConfirm = () => {
    console.log("confirmationValue ",confirmationValue)
    if(confirmationValue !== 'DELETE MY ACCOUNT'){
      setShowError(true)
      setTimeout(() => {setShowError(false)}, 3000)
      return
    }
    const payload = {
      "api_name": "deleteUser",
    "key": "JWT",
    "secret": "DOPAMINE",
    "user_id":userInfo.user_id,
    "reason": selectedValue,
    "description": description
    }
    apiCall.post("v1/user/deleteUserAccount", payload).then((res) => {
      console.log(res)
      const {success, msg} = res.data
      console.log(msg)
      if(!success){
        setErrors(currentState => {return {...currentState, delete: `${msg}, please try again.`}})
        resetErrors()
        return
      }
      setIsRequested(true)
      setConfirmationType('newRequest')
      setStep(5)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleRestore = () => {
    const payload = {
      "api_name": "deleteUser",
      "key": "JWT",
      "secret": "DOPAMINE",
      "user_id":userId
  }
    apiCall.post("v1/user/restoreUserAccount", payload).then((res) => {
      console.log(res)
      if(res.data.success){
        setConfirmationType("restored")
      }
    }).catch(error => {
      console.log(error)
    })
  }
  const screens = [
    {
      content: (
        <div className="account-deletion-container">
          <div>
          <p style={{ fontSize: 20, fontWeight: 600, margin: "46px 0 0 0" }}>
            Account deletion
          </p>
          <p style={{ color: "#6F7379", fontSize: 16, marginTop: "10px" }}>
            You’re about to submit a request to delete your Dopamine account.
          </p>

          <p style={{ fontSize: 20, fontWeight: 600, margin: "30px 0 0 0" }}>
            What is the exact data impacted?
          </p>
          <div
            style={{
              padding: "0 16px 16px 16px",
              fontWeight: 500,
              fontSize: 16,
              color: "#6F7379",
            }}
          >
            <ul style={{ margin: 0, padding: 0 , marginBottom: 50, marginTop: "10px"}}>
              <li style={{marginBottom: "10px"}}><b>Profile and Content Loss: </b>Your profile, along with all uploaded photos, posts, videos, and any other content, will be irrevocably deleted. Recovery of any added content will not be possible.</li>
              <li style={{marginBottom: "10px"}}><b>Discontinuation of Messenger Services: </b>Access to Dopamine.fit's messenger service will be terminated.</li>
              <li style={{marginBottom: "10px"}}><b>Interruption of Linked Services: </b>If you have connected your Dopamine.fit account to third-party wearable devices and health apps, such as Apple Health or Google Fit, this link will be disrupted. Post-deletion, you may need to directly contact these services to restore access or manage your data separately.</li>
              <li style={{marginBottom: "10px"}}><b>Visibility of Sent Messages:  </b>While your account will be deleted, messages sent to other users might remain visible in their inboxes, as these are stored separately from your account data.</li>
              
            </ul>
          </div>
          </div>
          <button
          onClick={handleClick}
          className="action-button-bottom"
        >
          Start account deletion
        </button>
        </div>
      ),
      
    },
    {
      content: (
        <>
          <p style={{ fontSize: 20, fontWeight: 600, margin: "46px 0 0 0" }}>
            Submit your account details to proceed further
          </p>
          <p style={{ color: "#6F7379", fontSize: 16 }}>
            You’re about to submit a request to delete your Dopamine account.
          </p>
          <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          error={errors.name}
          helperText={errors.name || ' '}
            fullWidth
            style={{
              borderRadius: 10,
              backgroundColor: "#eeeeee",
              margin: "20px 0 ",
              border: "none",
            }}
            id="outlined-basic"
            label="Name"
            variant="outlined"
            placeholder="Type here..."
          />

          <div style={{color: 'red'}}>{errors.notFound || ' '}</div>
          <TextField
            // className="form-input"
            error={errors.mobile}
            helperText={errors.mobile || ' '}
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
            placeholder="Eg. 9876543210"
            InputProps={{
              sx: {
                borderRadius: 3,
              },
              startAdornment: (
                <span
                  className="request-input-prefix"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <IWA
                    style={{
                      height: "1.4em",
                      width: "1.4em",
                      marginRight: "0.5rem",
                    }}
                  />
                  +91
                </span>
              ),
            }}
            inputProps={{ maxLength: 10 }}
            label="Phone"
            // value={data.phone}
            // onChange={(e) => {
            //   setError({ ...error, phone: !/^\d{10}$/.test(e.target.value) });
            //   setData({ ...data, phone: e.target.value });
            // }}
            // error={error.phone}
            // helperText={error.phone ? "Please enter valid phone number" : " "}
            // disabled={verifying}
            style={{ margin: "0 0 10px 0", width: "100%", borderRadius: 10 }}
          />
          <button
          onClick={() => handleSendOTP("mobile")}
          className="action-button-bottom"
          style={{margin: "0px auto 20px auto", boxSizing:'border-box'}}
        >
          <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>Send OTP  {isLoading && loginType == 'mobile' && <CircularProgress size={16}/>}</div>
        </button>
          <Divider style={{color: "#6F7379"}}>Or</Divider>
          <TextField
            // className="form-input"
            error= {errors.email}
            helperText={errors.email || " "}
            placeholder="Eg. abc@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            InputProps= {{
              sx: {
                borderRadius: 3,
              }
            }}
            /* InputProps={{
              startAdornment: (
                <span
                  className="request-input-prefix"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  
                 
                </span>
              ),
            }} */
            
            label="Email"
            // value={data.phone}
            // onChange={(e) => {
            //   setError({ ...error, phone: !/^\d{10}$/.test(e.target.value) });
            //   setData({ ...data, phone: e.target.value });
            // }}
            // error={error.phone}
            // helperText={error.phone ? "Please enter valid phone number" : " "}
            // disabled={verifying}
            style={{ margin: "20px 0 0px 0", width: "100%"}}
          />
          <div style={{margin: "10px 0 20px 0", display: "flex", justifyContent: "center", alignItems: "center", gap: "20px"}}>
            <button onClick={() => handleSendOTP("email")}
          className="action-button-bottom"
          ><div style={{display:'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>Send OTP to Email {isLoading && loginType == "email" && <CircularProgress size={16}/>}</div></button>
           {/*  <button style={{flex: 1}}>Send OTP to Apple</button> */}
          </div>
          
        </>
      ),
      
    },{content: (
      <div className="account-deletion-container">
        <div>
      <p style={{ fontSize: 20, fontWeight: 600, margin: "46px 0 20px 0" }}>
            Verify OTP
          </p>
          <TextField
          onChange={(e) => setOtp(e.target.value)}
            value={otp}
            error={errors.otp}
            helperText={errors.otp || ' '}
            fullWidth
            style={{
              borderRadius: 10,
              backgroundColor: "#eeeeee",
              marginBottom: 20,
              border: "none",
            }}
            id="outlined-basic"
            label="Enter OTP here"
            variant="outlined"
            placeholder="Type here..."
          />
          </div>
          <button className="action-button-bottom" onClick={handleVerifyOTP}>Verify OTP</button>

      </div>
    )},
    {
      content: (
        <div className="account-deletion-container">
          <div>
          <p style={{ fontSize: 20, fontWeight: 600, margin: "46px 0 0 0" }}>
            Why you are deleting your account?
          </p>
          <FormControl style={{ marginBottom: 44 }}>
            <RadioGroup
              onChange={handleRadioChange}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="I don’t need it anymore"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="I don’t need it anymore"
                control={<Radio />}
                label="I don’t need it anymore"
              />
              <FormControlLabel
                value="I have another account"
                control={<Radio />}
                label="I have another account"
              />
              <FormControlLabel
                value="The app is not working properly"
                control={<Radio />}
                label="The app is not working properly"
              />
              <FormControlLabel
                value="I’m shutting my business"
                control={<Radio />}
                label="I’m shutting my business"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <p style={{ fontWeight: 400, fontSize: 16, color: "#6F7379" }}>
            Describe your issue (optional)
          </p>
          <TextField
            onChange={(e) => setDescription(e.target.value)}
            value = {description}
            sx={{
              borderRadius: 10,
              backgroundColor: "#FFFFFF",
              border: "none",
              marginBottom: "50px"
            }}
            fullWidth
            id="outlined-multiline-static"
            multiline
            rows={4}
          />
          </div>
          <button
          onClick={handleClick}
          className="action-button-bottom"
        >
          Next
        </button>
        </div>
      ),
      
    },
    {
      content: (
        <div className="account-deletion-container">
          <div>
          <p style={{ fontSize: 20, fontWeight: 600, margin: "46px 0 0 0" }}>
            You're about to delete your account
          </p>
          <p style={{ color: "#6F7379", fontSize: 16 , marginBottom: '44px'}}>
          All data & features associated with your account
(Digital profile, business tools, customer information etc.) will be parmanently deleted in
14 days. The information can’t be recovered once the account deleted.
          </p>
          <p>Type ‘DELETE MY ACCOUNT’ as it is</p>
          <TextField
         
          onChange={(e) => setConfirmationValue(e.target.value)}
          
          error={showError}
            sx={{
              borderRadius: 10,
              backgroundColor: "#eeeeee",
              border: "none",
            }}
            fullWidth
            id="outlined-multiline-static"
            helperText={showError ? "Text does not match" : " "}
          />
          <p style={{color: 'red'}}>{errors.delete}</p>
          </div>
          <div>
          <button className="action-button-confirm" onClick={handleConfirm}>Confirm</button>
          <button
          onClick={() => setStep(0)}
          className="action-button-bottom"
        >
          Cancel
        </button>
        </div>
        </div>
      ),
    },
    /* {
      content: (
        <>
          <p style={{ fontSize: 20, margin: "46px auto 0 auto", textAlign: 'center'}}>
            Your account is being deleted
          </p>
          <p style={{width: '50%', color: "#6F7379", fontSize: 16 , margin: '20px auto 44px auto', textAlign: 'center'}}>
          You have {daysRemaining} days to cancel your
deletion request, if you don’t want to
lose any account data.
          </p>
          <button
          onClick={handleClick}
          className="action-button-bottom"
        >
          Restore your account
        </button>
          
        </>
      ),
      
    }, */
    {
      content: (
        <ConfirmationStep type={confirmationType} daysRemaining={daysRemaining} handleRestore={handleRestore}/>
      )
    },
  ];

  

  return (
    <>
      <Header />
      <div className="accountDeletion-wrapper">
        <Heading backButton={button} setStep={setStep} step={step} />
        {screens[step].content}
      </div>
      <Footer />
    </>
  );
};

export default React.memo(AccountDeletion);
