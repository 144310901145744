import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

import Close from "../assets/images/X.svg";
const ismobile = window.innerWidth <= 768;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "40px 80px",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  outline: "none",
};

const mobileStyle = {
  width: "80vw",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "32px 12px",
  backgroundColor: "#FFF",
  borderRadius: "12px",
  outline: "none",
};

function OnboardingModal({
  step,
  content,
  openModal,
  setOpenModal,
  setFormData,
  setUserDetails,
  setSelectedInterest,
  setStep,
  setDopamineScore,
}) {
  return (
    <div style={{ position: "relative" }}>
      <Modal
        hideBackdrop
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={ismobile ? mobileStyle : style}>
            <img
              src={Close}
              onClick={() => {
                setOpenModal(false);
                setFormData({});
                setUserDetails({});
                setSelectedInterest([]);
                setStep(0);
                setDopamineScore("");
              }}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                // display: "block",
                // marginLeft: ismobile ? "75vw" : "100%",
                cursor: "pointer",
              }}
            />

            <div className="trail" style={{ display: step === 5 && "none" }}>
              <div className="trail-detail-container">
                <div className="step-wrapper">01</div>
                <p>Your Details </p>
              </div>
              <div
                className={
                  step === 0
                    ? "trail-detail-container-unfilled"
                    : "trail-detail-container"
                }
              >
                <div
                  className={
                    step === 0 ? "step-wrapper-unfilled" : "step-wrapper"
                  }
                >
                  02
                </div>
                <p>Health Details</p>
              </div>
              <div
                className={
                  step === 1 || step === 0
                    ? "trail-detail-container-unfilled"
                    : "trail-detail-container"
                }
              >
                <div
                  className={
                    step === 1 || step === 0
                      ? "step-wrapper-unfilled"
                      : "step-wrapper"
                  }
                >
                  03
                </div>
                <p>Get your Dopamine score </p>
              </div>
            </div>

            <div
              className="content-container"
              style={{ marginTop: step === 5 ? "0px" : "30px" }}
            >
              {content[step]}
            </div>
          </Box>
        </>
      </Modal>
    </div>
  );
}

export default OnboardingModal;
